const verses = {
  hope: [
    {
      subtitle: "Psalm 31:24",
      text: "Be strong, and let your heart take courage, all you who wait for the Lord!",
    },
    {
      subtitle: "Psalm 42:5",
      text: "Why are you cast down, O my soul, and why are you in turmoil within me? Hope in God; for I shall again praise him, my salvation 6 and my God.",
    },
    {
      subtitle: "Romans 5:1-5",
      text: "Therefore, since we have been justified by faith, we have peace with God through our Lord Jesus Christ. Through him we have also obtained access by faith into this grace in which we stand, and we rejoice in hope of the glory of God. Not only that, but we rejoice in our sufferings, knowing that suffering produces endurance, and endurance produces character, and character produces hope, and hope does not put us to shame, because God’s love has been poured into our hearts through the Holy Spirit who has been given to us.",
    },
    {
      subtitle: "Romans 15:13",
      text: "May the God of hope fill you with all joy and peace in believing, so that by the power of the Holy Spirit you may abound in hope.",
    },
  ],
  joy: [
    {
      subtitle: "Psalm 16:11",
      text: "You make known to me the path of life; in your presence there is fullness of joy; at your right hand are pleasures forevermore.",
    },
    {
      subtitle: "Psalm 30:5",
      text: "For his anger is but for a moment, and his favor is for a lifetime. Weeping may tarry for the night, but joy comes with the morning.",
    },
    {
      subtitle: "Psalm 35:9",
      text: "Then my soul will rejoice in the Lord, exulting in his salvation.",
    },
    {
      subtitle: "1 Thessalonians 5:16-18",
      text: "Rejoice always, pray without ceasing, give thanks in all circumstances; for this is the will of God in Christ Jesus for you. ",
    },
  ],
  peace: [
    {
      subtitle: "Psalm 4:8",
      text: "In peace I will both lie down and sleep; for you alone, O Lord, make me dwell in safety. ",
    },
    {
      subtitle: "Matthew 5:9",
      text: "“Blessed are the peacemakers, for they shall be called sons of God.",
    },
    {
      subtitle: "John 14:27",
      text: "Peace I leave with you; my peace I give to you. Not as the world gives do I give to you. Let not your hearts be troubled, neither let them be afraid.",
    },
    {
      subtitle: "Romans 5:1",
      text: "Therefore, since we have been justified by faith, we have peace with God through our Lord Jesus Christ.",
    },
  ],
  generosity: [
    {
      subtitle: "Luke 6:38",
      text: "give, and it will be given to you. Good measure, pressed down, shaken together, running over, will be put into your lap. For with the measure you use it will be measured back to you.”",
    },
    {
      subtitle: "Acts 20:35",
      text: "In all things I have shown you that by working hard in this way we must help the weak and remember the words of the Lord Jesus, how he himself said, ‘It is more blessed to give than to receive.’”",
    },
    {
      subtitle: "2 Corinthians 9:6-7",
      text: "The point is this: whoever sows sparingly will also reap sparingly, and whoever sows bountifully will also reap bountifully. Each one must give as he has decided in his heart, not reluctantly or under compulsion, for God loves a cheerful giver.",
    },
  ],
  courage: [
    {
      subtitle: "Psalm 27:14",
      text: "Wait for the Lord; be strong, and let your heart take courage; wait for the Lord!",
    },
    {
      subtitle: "Psalm 31:24",
      text: "Be strong, and let your heart take courage, all you who wait for the Lord! ",
    },
    {
      subtitle: "1 Corinthians 16:13",
      text: "Be watchful, stand firm in the faith, act like men, be strong.",
    },
  ],
  prayer: [
    {
      subtitle: "Matthew 6:9-13",
      text: "Pray then like this: “Our Father in heaven, hallowed be your name. Your kingdom come, your will be done, on earth as it is in heaven. Give us this day our daily bread, and forgive us our debts, as we also have forgiven our debtors. And lead us not into temptation, but deliver us from evil.",
    },
    {
      subtitle: "Colossians 4:2",
      text: "Continue steadfastly in prayer, being watchful in it with thanksgiving.",
    },
    { subtitle: "1 Thessalonians 5:17", text: "pray without ceasing, " },
    {
      subtitle: "James 5:16",
      text: "Therefore, confess your sins to one another and pray for one another, that you may be healed. The prayer of a righteous person has great power as it is working.",
    },
  ],
  truth: [
    {
      subtitle: "John 14:6",
      text: "Jesus said to him, “I am the way, and the truth, and the life. No one comes to the Father except through me.",
    },
    {
      subtitle: "John 17:17",
      text: "Sanctify them in the truth; your word is truth.",
    },
  ],
  wisdom: [
    {
      subtitle: "Psalm 29:2",
      text: "Ascribe to the Lord the glory due his name; worship the Lord in the splendor of holiness.",
    },
    {
      subtitle: "Psalm 95:6",
      text: "Oh come, let us worship and bow down; let us kneel before the Lord, our Maker! ",
    },
    {
      subtitle: "James 1:5",
      text: "If any of you lacks wisdom, let him ask God, who gives generously to all without reproach, and it will be given him.",
    },
  ],
  temptation: [
    {
      subtitle: "John 8:34-36",
      text: "Jesus answered them, “Truly, truly, I say to you, everyone who practices sin is a slave to sin. The slave does not remain in the house forever; the son remains forever. So if the Son sets you free, you will be free indeed.",
    },
    {
      subtitle: "James 1:13-15",
      text: "Let no one say when he is tempted, “I am being tempted by God,” for God cannot be tempted with evil, and he himself tempts no one. But each person is tempted when he is lured and enticed by his own desire. Then desire when it has conceived gives birth to sin, and sin when it is fully grown brings forth death.",
    },
  ],
  adversity: [
    {
      subtitle: "Romans 5:3-4",
      text: "Not only that, but we rejoice in our sufferings, knowing that suffering produces endurance, and endurance produces character, and character produces hope",
    },
    {
      subtitle: "Philippians 4:6-7",
      text: "do not be anxious about anything, but in everything by prayer and supplication with thanksgiving let your requests be made known to God. And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.",
    },
    {
      subtitle: "1 Peter 1:6-7",
      text: "In this you rejoice, though now for a little while, if necessary, you have been grieved by various trials, so that the tested genuineness of your faith—more precious than gold that perishes though it is tested by fire—may be found to result in praise and glory and honor at the revelation of Jesus Christ.",
    },
  ],
  anxiety: [
    {
      subtitle: "Psalm 46:1",
      text: "God is our refuge and strength, a very present help in trouble.",
    },
    {
      subtitle: "Matthew 6:31,33",
      text: "Therefore do not be anxious, saying, ‘What shall we eat?’ or ‘What shall we drink?’ or ‘What shall we wear?’ ... But seek first the kingdom of God and his righteousness, and all these things will be added to you.",
    },
    {
      subtitle: "Philippians 4:6-7",
      text: "do not be anxious about anything, but in everything by prayer and supplication with thanksgiving let your requests be made known to God. And the peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus.",
    },
  ],
  bullying: [
    {
      subtitle: "Psalm 37:1",
      text: "Fret not yourself because of evildoers; be not envious of wrongdoers!",
    },
    {
      subtitle: "Matthew 7:12",
      text: "“So whatever you wish that others would do to you, do also to them, for this is the Law and the Prophets.",
    },
    {
      subtitle: "Matthew 5:38-39",
      text: "You have heard that it was said, ‘An eye for an eye and a tooth for a tooth.’  But I say to you, Do not resist the one who is evil. But if anyone slaps you on the right cheek, turn to him the other also.",
    },
    {
      subtitle: "Matthew 5:43-48",
      text: "“You have heard that it was said, ‘You shall love your neighbor and hate your enemy.’ But I say to you, Love your enemies and pray for those who persecute you, so that you may be sons of your Father who is in heaven. For he makes his sun rise on the evil and on the good, and sends rain on the just and on the unjust. ",
    },
    {
      subtitle: "Matthew 5:46-48",
      text: "For if you love those who love you, what reward do you have? Do not even the tax collectors do the same? And if you greet only your brothers, what more are you doing than others? Do not even the Gentiles do the same? You therefore must be perfect, as your heavenly Father is perfect.",
    },
  ],
  depression: [
    {
      subtitle: "Psalm 34:4",
      text: "I sought the Lord, and he answered me and delivered me from all my fears.",
    },
    {
      subtitle: "Psalm 43:5",
      text: "Why are you cast down, O my soul, and why are you in turmoil within me? Hope in God; for I shall again praise him, my salvation and my God.",
    },
  ],
  fear: [
    {
      subtitle: "Psalm 18:2",
      text: "The Lord is my rock and my fortress and my deliverer, my God, my rock, in whom I take refuge, my shield, and the horn of my salvation, my stronghold.",
    },
    {
      subtitle: "Psalm 31:24",
      text: "Be strong, and let your heart take courage, all you who wait for the Lord!",
    },
    {
      subtitle: "Psalm 91:9-10",
      text: "Because you have made the Lord your dwelling place— the Most High, who is my refuge- no evil shall be allowed to befall you, no plague come near your tent.",
    },
    {
      subtitle: "2 Timothy 1:7",
      text: "for God gave us a spirit not of fear but of power and love and self-control.",
    },
  ],
  loneliness: [
    {
      subtitle: "Psalm 27:10",
      text: "For my father and my mother have forsaken me, but the Lord will take me in.",
    },
    {
      subtitle: "Hebrews 13:5-6",
      text: "Keep your life free from love of money, and be content with what you have, for he has said, “I will never leave you nor forsake you.” So we can confidently say, “The Lord is my helper; I will not fear; what can man do to me?”",
    },
    {
      subtitle: "1 Peter 5:6-7",
      text: "Humble yourselves, therefore, under the mighty hand of God so that at the proper time he may exalt you, casting all your anxieties on him, because he cares for you.",
    },
    {
      subtitle: "Psalm 23:1-3",
      text: "The Lord is my shepherd; I shall not want. He makes me lie down in green pastures. He leads me beside still waters. He restores my soul. He leads me in paths of righteousness for his name's sake.",
    },
    {
      subtitle: "Psalm 23:4",
      text: "Even though I walk through the valley of the shadow of death, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
    },
    {
      subtitle: "Psalm 23:5-6",
      text: "You prepare a table before me in the presence of my enemies; you anoint my head with oil; my cup overflows. Surely goodness and mercy shall follow me all the days of my life, and I shall dwell in the house of the Lord forever.",
    },
  ],
  "pain-sickness": [
    {
      subtitle: "2 Corinthians 12:9-10",
      text: "But he said to me, “My grace is sufficient for you, for my power is made perfect in weakness.” Therefore I will boast all the more gladly of my weaknesses, so that the power of Christ may rest upon me. For the sake of Christ, then, I am content with weaknesses, insults, hardships, persecutions, and calamities. For when I am weak, then I am strong.",
    },
    {
      subtitle: "James 5:14-15",
      text: "Is anyone among you sick? Let him call for the elders of the church, and let them pray over him, anointing him with oil in the name of the Lord. And the prayer of faith will save the one who is sick, and the Lord will raise him up. And if he has committed sins, he will be forgiven.",
    },
  ],
  relationships: [
    {
      subtitle: "Proverbs 13:20",
      text: "Whoever walks with the wise becomes wise, but the companion of fools will suffer harm.",
    },
    {
      subtitle: "Proverbs 17:17",
      text: "A friend loves at all times, and a brother is born for adversity.",
    },
    {
      subtitle: "Proverbs 18:24",
      text: "A man of many companions may come to ruin, but there is a friend who sticks closer than a brother.",
    },
    {
      subtitle: "1 Corinthians 15:33",
      text: "Do not be deceived: “Bad company ruins good morals.”",
    },
  ],
  stress: [
    {
      subtitle: "Matthew 11:28-29",
      text: "Come to me, all who labor and are heavy laden, and I will give you rest. Take my yoke upon you, and learn from me, for I am gentle and lowly in heart, and you will find rest for your souls.",
    },
    {
      subtitle: "John 14:27",
      text: "Peace I leave with you; my peace I give to you. Not as the world gives do I give to you. Let not your hearts be troubled, neither let them be afraid.",
    },
    {
      subtitle: "Philippians 4:11-13",
      text: "Not that I am speaking of being in need, for I have learned in whatever situation I am to be content. I know how to be brought low, and I know how to abound. In any and every circumstance, I have learned the secret of facing plenty and hunger, abundance and need. I can do all things through him who strengthens me.",
    },
  ],
};

export default verses;
