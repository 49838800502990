<template>
  <base-section id="verses" dark>
    <base-section-heading
      icon="mdi-book-open-page-variant"
      title="What does the Bible say about..."
    />
    <v-container>
      <v-row justify="center" align="center">
        <v-col
          v-for="verse in verses"
          :key="verse"
          cols="6"
          md="3"
          sm="4"
          class="text-center"
        >
          <a
            :href="`${church ? `/${church}` : ''}/help/${verse}`"
            align-self="center"
            ><base-btn :max-width="164"><span v-text="verse"/></base-btn
          ></a>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
// Extensions
import verses from "@/lib/verses";

export default {
  name: "Helps",

  computed: {
    church() {
      return this.$route.params.church;
    },
    verses() {
      return Object.keys(verses);
    }
  }
};
</script>
